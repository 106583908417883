(function (doc) {
  const createDiv = function (url, id) {
    const div = doc.createElement('div');
    div.classList.add('shoutout-embed');
    div.setAttribute('data-wall', url);
    div.setAttribute('id', id);
    div.style.width = '100%';
    return div;
  };
  const onLoad = function () {
    const current = doc.currentScript;
    const wall = current.getAttribute('data-wall');
    const wallId = wall.replace(/\s/g, '-');
    if (wall) {
      // Find callout blocks with a 📣
      const calloutBlocks = Array.from(
        doc.querySelectorAll('.notion-callout')
      ).filter((callout) => {
        return (
          callout.querySelectorAll('.notion-page-icon')[0].textContent === '📣'
        );
      });
      if (calloutBlocks.length) {
        calloutBlocks.forEach((block) => {
          block.removeAttribute('class');
          block.innerHTML = '';
          block.style.width = '100%';
          block.appendChild(createDiv(wall, wallId));
        });
      }
      const script = doc.createElement('script');
      script.src = `https://shoutout.io/static/website/js/embed.js`;
      doc.body.appendChild(script);
    }
  };
  if (doc.readyState !== 'loading') {
    onLoad();
  } else {
    doc.addEventListener('DOMContentLoaded', onLoad);
  }
})(document);